<template>
    <div class="tooltip" @mouseenter="showTooltip" @mouseleave="hideTooltip">
        <slot></slot>
        <TransitionPopup>
            <div
                v-if="isVisible && text"
                ref="contentRef"
                :class="['tooltip__item', positionClass]"
            >
                <span v-html="text"></span>
            </div>
        </TransitionPopup>
    </div>
</template>

<script setup>
    import { ref, computed } from "vue";

    import TransitionPopup from "@/vue/components/el-ui/TransitionPopup.vue";
    import { useAdaptiveOverflowHandler } from "@/hooks.js";

    const props = defineProps({
        text: String,
        position: {
            type: String,
            default: "top",
        },
    });

    const isVisible = ref(false);
    const contentRef = ref(null);

    const positionClass = computed(() => {
        switch (props.position) {
            case "bottom":
                return "tooltip--bottom";
            case "bottom-center":
                return "tooltip--bottom-center";
            case "left":
                return "tooltip--left";
            case "right":
                return "tooltip--right";
            case "top-center":
                return "tooltip--bottom-center";
            case "top":
            default:
                return "tooltip--top";
        }
    });

    const showTooltip = () => {
        isVisible.value = true;
    };

    const hideTooltip = () => {
        isVisible.value = false;
    };

    useAdaptiveOverflowHandler(contentRef, isVisible);
</script>
